
.product__title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;
  line-height: 1.2;
  color: $txt;
}


.product__figure {
  position: relative;
  height: 0;
  padding-top: 92%;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition:all 0.3s ease-out;
  }

}

.product__bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.product__discount {
  width: 50px;
  height: 50px;
  background: $violet;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font2-family-name;
}

.product__discount--2 {
  width: 90px;
  height: 90px;
  font-size: 33px;
}

.product__priceOld {
  text-decoration: line-through;
  color: $txt;
  font-size: 18px;
}

.product__price {
  color: $red;
  font-size: 18px;
  font-weight: 700;
}

.product__priceNew {
  color: $main;
}

.product__item {
  margin-bottom: 50px;
  position: relative;

  .product__date{
    margin-bottom:10px;
  }

  .product__price{
    margin-top:auto;
  }

  .product__date, .product__title, .product__price{
    padding-left:25px;
    padding-right:20px;
  }
}

.product__label {
  background: $main2;
  color: $main;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 10px;
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;
  white-space: nowrap;
  font-family: $font2-family-name;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    left: 4px;
    top: 4px;
    border: 1px solid $main2;
  }

  @media(max-width:$grid-breakpoints-xxl){
    padding: 7px 10px;
    top: 18px;
  }

}

.product__date {
  color: $txt-light;
  font-size: 14px;
}

.product__link {

  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius:15px;
  padding-bottom: 20px;
  height:100%;
  @media(hover: hover) {

    &:hover,
    &.active {
      .product__figure img{
        transform: translate(-50%, -50%) scale(1.1);
      }

      .product__title {
        color: $main;
      }
    }
  }
}
