@import "variables";
@import "fonts";


html {
  font-size: 16px;
  scroll-behavior: smooth;

  @media(max-width: $grid-breakpoints-md) {
    font-size: 14px;
  }

  @media(max-width: 360px) {
    font-size: 12px;
  }
}

body {
  line-height: 1.5;
  font-size: $font16;
  font-family: $font-family-name;
  font-weight: 400;
  color: $txt;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
}

* {
  box-sizing: border-box
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: $font-family-name;
}


.tooltip {
  position: relative;
  cursor: pointer;


  span {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    background: #fff;
    z-index: -1000;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    padding: 5px;
    font-size: $font12;
    color: $txt;
    width: 0;
    height: 0;
    overflow: hidden;
    text-align: center;
    line-height: 1.2;
  }

  &:hover {
    span {
      opacity: 1;
      z-index: 1;
      height: auto;
      width: auto;
    }
  }
}

.tooltip--i{
  width:25px;
  height:25px;
  display:inline-block;
  background-image: url("../img/ico-calendar.svg");
  background-position: left center;
  background-repeat: no-repeat;
}


iframe,
video {
  max-width: 100%;
  width: 100%;

}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.lh--2 {
  line-height: 2 !important;
}


.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 600 !important;
}

.font--9 {
  font-size: 9px !important;
}

.font--10 {
  font-size: $font10 !important;
}

.font--12 {
  font-size: $font12 !important;
}

.font--14 {
  font-size: $font14 !important;
}

.font--18 {
  font-size: $font18 !important;
}

.font--20 {
  font-size: $font20 !important;
}

.main {
  color: $main !important;
}

.grey {
  color: $txt-dark !important;
}

.grey--light {
  color: $txt-light !important;
}

.white {
  color: #fff !important;
}

.black {
  color: $txt !important;
}

.main2 {
  color: $main2 !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: right !important;
}


/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid $main2;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


a {
  color: $main;
  text-decoration: none;

  @media(hover: hover) {
    &:hover {
      color: $main-hover;
    }
  }

  &:focus-visible {
    outline-offset: 2px;
  }
}


h1,
h2,
h3 {
  letter-spacing: initial;
  line-height: 1.3;
  font-weight: 500;

  a {
    color: inherit;
  }
}

h1,
.h1 {
  font-size: 48px;
  margin-bottom: 40px;
}

h2 {
  font-size: 48px;
  margin-bottom: 40px;
}

h3 {
  font-size: 34px;
  margin-bottom: 25px;

}


.title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  color:$txt;
}

.title2 {
  font-size: 38px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 35px;
  color:$txt;
}

.title3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  color:$txt;
}

.description {
  text-align: center;
  font-size: $font20;
  margin-bottom: 50px;
}

h4,
h5,
h6 {
  line-height: 1.3;
  font-size: $font20;
  font-weight: 700;
  margin-bottom: 10px;
}

.list--own {
  ul {
    padding-left: 20px;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 5px;

      &:before {
        content: ">";
        position: absolute;
        top: 0px;
        left: -20px;
        font-weight: 700;
      }

      ul {
        padding-left: 15px;
      }

      li {
        &:before {
          top: -8px;
          left: -13px;
          font-size: 20px;
          content: ".";
          font-weight: 700;
        }

      }
    }
  }
}

@media(max-width: $grid-breakpoints-xxl) {
  h1, h2 {
    font-size: 42px;
  }
  .title2{
    font-size:34px;
  }
  h3, .title {
    font-size: 30px;
  }

  .title3{
    font-size:24px;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  h1, h2 {
    font-size: 36px;
  }
  .title2{
    font-size:30px;
  }
  h3, .title {
    font-size: 26px;
  }
  .title3{
    font-size:20px;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  h1, h2 {
    font-size: 32px;
  }
  .title2{
    font-size:26px;
  }
  h3, .title {
    font-size: 22px;
  }

  .title3{
    font-size:20px;
  }
}


ul,
ol {
  list-style: none;

}

.page__txt {
  line-height: 1.6;
  color:$txt-dark;
  font-size:18px;

  img {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    display: inline-block;
    height: auto;
  }

  ul,
  ol {
    padding-left: 30px
  }

  ul {
    li:before{
      content:"•";
      color:$main;
      padding-right:8px;
    }
  }

  ol {
    list-style: decimal;
    li::marker{
      color:$main;
      font-weight:700;
    }
  }

  ol,
  ul,
  p {
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }


  h2:not(:first-child) {
    margin-top: 35px;
  }

  h3:not(:first-child) {
    margin-top: 25px;
  }

  @media(max-width: $grid-breakpoints-sm){
    font-size:16px;
  }

}


input:not([type="date"], [type="number"], [type="radio"], [type="checkbox"]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: $font-family-name;
}


button {
  color: $txt;
}

input,
textarea,
select {

  border: 1px solid $input-border;
  line-height: 1.6;
  padding: 10px 15px;
  color: $input;
  font-family: $font-family-name;
  font-size: $font16;

  &::placeholder {
    font-family: $font-family-name;
  }

}

::placeholder {
  color: $placeholder;
  font-weight: 400;
  font-style: inherit;
  font-family: $font-family-name;
}

.disabled {
  color: $placeholder;
}


hr {
  border: none;
  border-top: 1px solid #DDDDDD;
  margin-top: 40px;
  margin-bottom: 40px;
}

table {
  max-width: 100%;
  font-size: $font14;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;

  * {
    line-height: 1.4;
  }

  th {
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid $line;
    padding: 10px 10px;
    vertical-align: middle;

  }

  td {
    border-bottom: 1px solid $line;
    padding: 10px 10px;
    vertical-align: middle;
  }

  @media(min-width: 1400px) {
    font-size: $font16;
  }

  thead {
    position: relative;

    th {
      border: none;
      padding: 12px 10px 12px 10px;
      border-top: 2px solid $main2;
      border-bottom: 2px solid $main2;
    }
  }

  .align-right {
    text-align: right;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  tbody {

    tr:first-child {

      th,
      td {
        border-top-width: 5px;
      }
    }

  }
}


.table__wrapper {
  position: relative;

  table {
    width: 100%;
    position: relative;

    a {
      display: inline-block;
      padding: 1px 5px;
      text-decoration: underline;
      font-weight: bold;
    }
  }


  @media(max-width: 768px) {
    overflow: auto;

    table {
      min-width: 800px;
      width: initial;


      th,
      td {
        padding: 8px 5px;
      }
    }

    &.table__wrapper--big {
      table {
        min-width: 1300px;
        width: initial;
      }
    }
  }
}


.btn--table {
  background: $main;
  color: #fff;
  padding: 5px 10px !important;

  margin-top: 7px;
  text-decoration: none !important;

  @media(hover: hover) {
    &:hover {
      background: $main2;
      color: #fff;
    }
  }
}


.listBlue__wrapper{
  list-style: none !important;
  padding-left:0 !important;
  color:$txt-dark;
  li{
    padding-left:30px;
    position:relative;
    margin-bottom:20px;
    &:before{
      content:"";
      width:7px;
      height:100%;
      position:absolute;
      border-radius:4px;
      background: $main;
      top:0;
      left:0;
    }
  }
}


.listCheck__wrapper{
  list-style: none !important;
  padding-left:0 !important;
  color:$txt-dark;
  li{
    padding-left:30px;
    position:relative;
    margin-bottom:10px;
    &:before{
      content:"";
      width:20px;
      height:24px;
      display:inline-block;
      background-image:url("../img/blue-check.svg");
      background-position:center left;
      background-repeat: no-repeat;
      background-size: 16px;
      position:absolute;
      top:0;
      left:0;
    }
  }
}
