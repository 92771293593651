/* lexend-deca-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lexend-deca-v21-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-500 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/lexend-deca-v21-latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lexend-deca-v21-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-condensed-v27-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-condensed-v27-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-800 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/roboto-condensed-v27-latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}