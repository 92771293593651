@import "bc";

.pageHero__wrapper {
  border-bottom: 1px solid $line;

  margin-bottom:40px;

  h1, h2{
    margin-bottom:25px;
  }

  @media(max-width: $grid-breakpoints-sm){
    margin-bottom: 30px;
  }
}