.breadcrumbs__list {
  display: flex;
  padding: 15px 0;
  flex-wrap: wrap;

  &.breadcrumbs--border {
    border-bottom: 1px solid $line;
  }

  span,
  a {
    font-size: 14px;
    color:#282828;
    display: inline-block;
    padding: 7px 0;
    line-height: 1;
    font-weight: 300;

    &:before {
      margin-right: 10px;
      color:#282828;
      content: "/";
    }
  }

  li:first-child {
    a:before {
      content: none;
    }
  }

  a {
    margin-right: 10px;

    &:focus,
    &:hover {
      outline: none;
      color: $main2;
    }
  }

  @media(max-width: $grid-breakpoints-sm) {
    span,
    a {
      font-size: 12px;
    }
  }
}