.go-top {
  position: absolute;
  top: -40px;
  right: 0;
  display: inline-block;
  background-image: url("../img/ico_top.svg");
  background-repeat: no-repeat;
  background-position: center top;
  border: none;
  background-color: transparent;
  padding-top: 75px;
  font-weight: 700;

  span {
    font-size: $font12;
    color: #AAAAAA;
    text-transform: uppercase;
    transform: rotate(-90deg);
    display: block;
    padding-right: 65px;
  }

}



.footer {
  position: relative;
  background: $bg-grey;
  z-index: 1;
  line-height: 1.8;
}


.footer__center {
  padding-top: 60px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  position:relative;

}


.footer__col {
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight:700;
  }
}

.footer__list {
  list-style: none;
  padding-left: 0;

  a {
    color: $txt;
    font-size: 14px;
    display: inline-block;
    padding: 4px 0;
    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }
  }
}


.footer__social {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top:10px;

  a {
    min-width: 48px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $txt;
      transform-origin: center;
      transform: scale(2);
    }
  }
}

.footer__contact {
  font-size: 14px;

  a {
    font-weight: 700;
  }

  span:after {
    content: "";
    width: 102px;
    height: 1px;
    background: rgba(230, 226, 219, 0.2);
    display: inline-block;
    margin-left: 10px;
    margin-right: 5px;
    position: relative;
    bottom: 0px;
  }

  p {
    margin-bottom: 5px;
  }

  p:nth-child(2) span:after {
    width: 70px;
  }
}

.footer__bottom {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 2px solid #ECECEC;
  position: relative;
  z-index: 10;
  font-size: 11px;
  color:$txt-light;

  .container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img{
    opacity:0.5;
  }
}

.footer__brand {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  gap: 5px;

  a {
    position: relative;
    top: -2px;
  }
}





@media(max-width: $grid-breakpoints-sm) {

  .footer__col h3 {
    margin-bottom: 15px;
  }
  .footer .footer__col{
    margin-bottom:50px;
  }

  .footer__brand{
    img{
      height:8px;
      width:auto;
    }
  }
  .footer__bottom{
    opacity:0.7;
  }

}


