.history--back {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 0px;

  &:before {
    content: "" !important;
    width: 50px;
    height: 50px;
    background: url("../img/swiper-arrow.svg") center no-repeat;
    background-size: 16px;
    display: block;
  }
}

.link--underline {
  text-decoration: underline;
  color: $txt;

  @media(hover: hover) {
    &:hover {
      color: $main2;
    }
  }
}

.link--black{
  color:$txt !important;
  @media(hover:hover) {
    &:hover {
      color: $main !important;
    }
  }
}

.link {

  background-size: 16px;
  color:$txt;
  display:block;
  padding: 3px 0 3px 25px;
  font-size:18px;

  @media(hover:hover) {
    &:hover {
      color: $main;
    }
  }
}

.link--tel {
  padding-left: 22px;
  font-weight:600;
  background: url("../img/ico-phone.svg") center left no-repeat;
  background-size: 16px;
}

.link--tel2 {
  background: url("../img/ico-phone2.svg") center left no-repeat;
}

.link--mail {  background: url("../img/ico-mail.svg") center left no-repeat;

}

.link--underline-main {
  text-decoration: underline;
  color: $main;

  @media(hover: hover) {
    &:hover {
      color: $main2;
    }
  }
}


.btn {
  border: none;
  background-color: $main;
  color: #fff !important;
  font-size: $font16;
  font-weight: 600;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-out;
  border: 1px solid transparent;
  line-height: 1.2;
  padding: 5px 30px;
  vertical-align: middle;
  text-align: center;
  border-radius: 25px;


  @media(hover: hover) {
    &:hover {
      background-color: $main-hover;
      color: #fff !important
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    padding: 15px 30px;
    min-width: initial;
  }
}

.btn--red {
  background-color: $red;
}

.btn--bell{
  padding-left:45px;
  background-image: url("../img/ico-bell.svg");
  background-position: left 20px center;
  background-repeat: no-repeat;
  background-size: 14px;
}


.btn--arrow {
  padding-right: 70px;
  text-align: left;

  &:after {
    content: url("../img/arrow-right.svg");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    width: 18px;
    display: inline-block;
  }

  @media(max-width: $grid-breakpoints-md) {
    padding-right: 60px;
    &:after {
      right: 25px;

    }
  }
}


.btn--white {
  background: #fff;
  color: $main !important;
}

.btn--sale{
  padding-left:45px;
  background-image: url("../img/ico-sale.svg");
  background-position: left 10px center;
  background-repeat: no-repeat;
  background-size: 32px;
 display:inline-flex;
  &:first-letter{
    text-transform: uppercase;
  }

  @media(max-width:$grid-breakpoints-sm){
    span{
      display:none;
    }
  }
}

.btn--big {
  font-size: 20px;
  padding: 10px 50px;
  @media(max-width: $grid-breakpoints-md) {
    font-size: 18px;
    padding: 12px 40px;
  }
}