@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

.menu-is-fixed {
  padding-top: 130px;
}

.subpage .header {
  border-bottom: 1px solid #EFF1F7;
}


.header {
  background: rgba(255, 255, 255, 0.7);
  position: relative;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;

  &.fixed-header-scroll {
    position: fixed;
    top: 0;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    background: #fff;

    .header__top {
      display: none;
    }

    .header__bottom {
      height: 50px;
    }

    .btn--bell{
      min-height:40px;
    }
    .header__logo {
      padding-right: 30px;
    }

  }
}

.header__topBg {
  background: #F3F3F3;
}

.header__infoTel {
  margin-left: auto;
  margin-right: auto;

  span {
    color: $txt-light;
    font-size: 13px;
    margin-right: 10px;
  }
}


.header__top {
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 45px;
  padding-left: 300px;
}


.header__logo {
  max-width: 210px;
  flex: 0 0 210px;

  img {
    width: 100%;
  }
}


.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: $font20;
  right: 50px;
  line-height: 50px;
  bottom: 8px;

  strong {
    margin-left: -20px;
  }


  span {
    width: 26px;
    height: 2px;
    background: $txt;
    display: block;
    position: absolute;
    left: 12px;
    top: 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: center;

    &:nth-child(2) {
      top: 25px;
    }

    &:nth-child(3) {
      top: 35px;
    }

  }

  &.open span {
    &:first-child {
      transform: rotate(45deg);
      top: 24px;
    }

    &:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 24px;
      transform: rotate(-45deg);
    }
  }

}


.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  position: relative;
  z-index: 10;
  width: 100%;
}


.header__social {
  display: flex;
  align-items: center;
  margin-top: -5px;


  a {
    width: 48px;
    height: 48px;
    display: inline-block;
    fill: $txt;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.link--social {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-color: trnasparent;
}

.link--fb {
  background-image: url("../img/fb.svg");
}

.link--insta {
  background-image: url("../img/insta.svg");
}


header > ul > li {
  position: relative;
}


.nav__wrapper {
  display: flex;
  align-items: center;
  gap: 0px;
  width: 100%;
  justify-content: center;
}

.nav {

  a {
    color: $txt;
    font-size: 16px;
    font-weight: 700;
    padding: 17px 12px;
    display: inline-block;

    transition-property: color;
    font-weight: 700;
    white-space: nowrap;


    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }
  }


}

.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}


.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

.menu-open {
  .menu-right__wrapper {
    z-index: 0;
  }
}


.menu__item--has-children {
  position: relative;

  //&:after {
  //  content: "";
  //  display: inline-block;
  //  width: 8px;
  //  height: 8px;
  //  border-left: 2px solid #707070;
  //  border-bottom: 2px solid #707070;
  //  position: absolute;
  //  right: 10px;
  //  top: 22px;
  //  transform: rotate(-45deg);
  //}
}


@media(min-width: $grid-breakpoints-xl) {

  .nav a.active {
    color: $main;
  }

  .menu__item--has-children {
    position: relative;

    > a {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .nav__level-2 {
      padding: 15px 20px 0 20px;
      position: absolute;
      width: 180px;

      gap: 20px;
      border-radius: 15px;
      left: 0px;
      opacity: 0;
      z-index: -1000;
      top: 100%;
      height: 0;
      overflow: hidden;
      background: #fff;
      border: 3px solid #EFF3F9;
      box-shadow: 0px 10px 10px 0 rgb(0, 0, 0, 0.25);

      ul {
        &:before {
          content: "";
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 12px solid #EFF3F9;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      li {
        border-top: none;

        a {
          font-size: $font16;
          color: $txt;
          display: block;
          padding: 3px 0px 3px 0px;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          position: relative;
          font-weight: 700;
          white-space: initial;
        }
      }

      a {
        &:hover {
          color: $main;
        }

        &:focus-visible {
          outline-color: $main2;
          outline-offset: -2px;
        }
      }
    }

    &.open .nav__level-2 {
      z-index: 30;
      height: initial;
      opacity: 1;
      overflow: visible;
      transition: opacity 0.3s ease-in-out;
      padding-bottom: 10px;
      padding-top: 15px;

    }
  }

  .menu__item--has-children:hover {
    > a {
      color: $main
    }

    .nav__level-2 {
      z-index: 30;
      height: initial;
      opacity: 1;
      overflow: visible;
      transition: opacity 0.3s ease-in-out;
      padding-bottom: 10px;
      padding-top: 20px;
    }
  }


  @media(max-width: 1360px) {
    .nav a {
      font-size: 14px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }


}


@media(max-width: $grid-breakpoints-xl) {
  .menu__item--has-children {
    padding-right: 50px;

    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-left: 2px solid $txt;
      border-bottom: 2px solid $txt;
      position: absolute;
      right: 10px;
      top: 18px;
      transform: rotate(-45deg);
      transition:all 0.3s ease-out;
    }

    &.m-open {
      &:after {
        top: 22px;
        transform: rotate(135deg);
      }
    }
  }

  .header {

    &.fixed-header-scroll {
      .header__top {
        align-items: center;
        height: 50px;
      }


      .headerForm input {
        background: #fff;
        border: 1px solid #AAAAAA;
      }

      .nav-toggle {
        top: 0px;
      }

      .nav__level-2 {
        top: 50px;
        height: calc(100vh - 50px);
      }

      .activeLi > a {
        top: 50px;
      }
    }
  }

  .header__social {
    margin-right: 10px;
  }


  .header {
    transition: background 0s ease-out 0.3s;
  }

  .header__top {
    padding-left: 60px;
    position: relative;
    align-items: center;
    gap: 20px;
  }

  .headerForm {
    padding-right: 20px;
  }

  .header__right {
    gap: 0;
  }

  .header__bottom {
    position: static;
    flex: 0 0 0;
    height: 65px;
    padding-right: 60px;
  }


  .btn__login {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    padding: 0;
    text-indent: -1000px;
    overflow: hidden;
    height: 48px;
    background: transparent url("../img/user.svg") center no-repeat;
    background-size: 15px;
  }

  .menu__item--has-children {
    position: relative;


    > a {
      width: 100% !important;
      position: relative;
      z-index: 1;

    }

    &:after {
      width: 12px;
      height: 12px;
      top: 18px;
      transform: rotate(-135deg);
      z-index: 0;
    }
  }

  .nav__level-1 > li {
    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    > a {
      padding: 12px 0;
      font-size: 19px;
    }
  }
  .headerForm {
    width: 100%;
  }
  .nav .nav__wrapper {
    // display: none;
    position: absolute;
    top: 100%;
    display: block;
    background: $bg-grey;
    width: 100%;
    left: -100%;
    padding: 0 20px 80px 20px;
    height: calc(100vh - 80px);
    margin-top: 0;
    transition: all 0.3s ease-out;


    &.open {
      left: 0;
      box-shadow: -2px 5px 5px 0 rgba(0, 0, 0, 0.2);
    }

    li {
      width: 100%;
    }

    > li:last-child {
      margin-bottom: 25px;
    }
  }


  .nav__level-2 {

    display: none;

    ul {
      padding-left: 20px;
    }


    a {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 0;
    }

    &.m-open {

    }
  }


  .nav {
    width: 100%;
  }

  .nav-toggle {
    display: block;
    position: absolute;

  }

  .nav__wrapper.nav__level-1 ul.open {
    max-height: 600px;
    overflow: auto;
  }

  .nav {
    margin-left: auto;
    flex: 0 0 0px;
    max-width: 0px;
  }


  .header.fixed-header-scroll {

    .header__bottom {
      margin-left: auto;
      justify-content: flex-end;
    }


    .header__left {
      padding-top: 0;
      padding-bottom: 0;
    }


  }

  .header__social {
    display: none;
  }

  .nav-toggle {
    right: 20px;
  }

  .header .btn--bell {
    min-height: 40px;
    padding: 5px 15px 5px 35px;
    background-position: left 13px center;

    span {
      display: none;
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .header__top {
    padding-left: 20px;
    gap: 10px;
  }
  .header__logo {
    max-width: 120px;
    flex: 0 0 120px;
  }

  .headerControls {
    gap: 10px !important;
  }
  .header.fixed-header-scroll .header__logo {
    padding-right: 0px;
  }
}


.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

@media(max-width: 440px) {
  .header {
    .headerControls__link, .link--tel {
      font-size: 13px;
    }
  }
}

@media(max-width: 390px) {
  .header {
    .headerControls__link, .link--tel {


      svg {
        display: none;
      }
    }
  }
}

