
.blog {
  background: $bg-grey;
}

.blog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 0;
}

.blog__item {
  flex: 0 0 100%;

  a {
    display: block;
    height: 100%;
  }
}

.blog__figure {
  width: 100%;
  padding-top: 64%;
  position: relative;
  overflow: hidden;
  height: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    transform: translate(-50%, -50%);

    transition:all 0.3s ease-out;
  }

}

.blog__title {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 400;
  margin-bottom:0;
  color:$txt;

}

.blog__data {
  color: $txt-light;
  position: relative;
  font-size: 14px;
  display: block;
  line-height: 1;
}

.blog__txt{
  padding:30px ;
}

.blog__data--p0 {
  padding-left:30px;
  padding-right: 0px;
}



.blog__link {
  padding-bottom:60px;
  position:relative;
  border-radius:15px;
  background:#fff;
  &:after {
    content: "";
    position: absolute;
    right: 30px;
    bottom: 30px;
    transition: all .3s ease-out;
    width: 40px;
    height: 40px;
    background-image: url("../img/arrow-right-blue.svg");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius:50%;
  }
  @media(hover: hover) {
    &:hover {
      &:after{
        background-color:$main2;
      }

      .blog__figure img{
        transform: translate(-50%, -50%) scale(1.1);
      }

      .blog__title {
        color: $main;
      }
    }
  }
}


@media(min-width: $grid-breakpoints-sm) {

  .blog__item {
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
}

@media(min-width: $grid-breakpoints-lg) {
  .blog__item {
    max-width: calc(33.33% - 20px);
    flex: 0 0 33.33%;
  }
}







