.tabs__navlist {
  display: inline-flex;
  gap: 10px 25px;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  margin-bottom: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.tabs__nav--btn {
  padding: 9px 20px;
  border: 1px solid #B5B5B5;
  background: transparent;
  display: inline-block;
  border-radius: 25px;
  background-position: left 18px center;
  background-repeat: no-repeat;

  font-size: 20px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  color: $txt;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }

  &:focus-visible {
    border-color: $main;
    color: $main;
  }

  &[aria-selected="true"], &.active {
    border-color: $main;
    color: #fff;
    background-color: $main;
    padding-left: 44px;
    background-image: url("../img/check-white.svg");


  }

}

@media(max-width: $grid-breakpoints-xxl) {
  .tabs__navlist {
    gap: 8px;
  }
  .tabs__nav--btn {
    font-size: 18px;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .tabs__nav--btn {
    padding: 8px 15px;
  }
}
@media(max-width: $grid-breakpoints-sm) {
  .tabs__nav--btn {
    font-size: 14px;

  }
  .tabs__nav--btn[aria-selected=true]{
    background-position: left 14px center;
    padding-left: 34px !important;
  }
}



.tabs__panel {
  display: none;

  &.is-current {
    display: block;
  }

  @media(min-width: $grid-breakpoints-md) {
    margin-bottom: 30px;;
  }
}

.tabProduct__container {
  position: relative;

  .swiper-slide {
    height: initial;
  }
}