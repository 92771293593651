
.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.of-contain {
  object-fit: contain !important;
}

.bg--main2 {
  background: $main2 !important;
}

.bg--main {
  background: $main !important;
}

.bg--grey {
  background: $bg-grey !important;
}

.bg--light {
  background: $bg-blue !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -100;
  opacity: 0;
}


.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: $txt !important;
  display: block;

}

.alert-success {
  background-color: #ebf3e8 !important;
  // border-color: #D6E9C6;
  color: #007A3B !important;
}

.alert-info {
  background-color: #eaf6fb !important;
  // border-color: rgb(103, 121, 171);
  //     color: rgb(103, 121, 171);; }
}

.alert-warning {
  background-color: #f8f8cb !important;
  // border-color: #FBEED5;
  // color: #C09853;
}

.alert-danger {
  background-color: #faeded !important;
  // border-color: #f1e3e5;
  color: #ca0038 !important;
}

.alert-grey {
  background-color: $bg-grey !important;
  // border-color: #f1e3e5;
  // color: $red;
}

.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}