
.headerControls {
  display: flex;
  gap: 50px;
  align-items: center;
  list-style: none;
  padding: 0;
}


.headerControls__link {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  position: relative;
  border: none;
  font-size: 16px;
  color: $main;
  line-height: 1.5;
  svg {
    transition: all 0.3s ease-in-out;
    //stroke: $main !important;
    fill: $main;
  }

  .svg2 {
    stroke: $main !important;
  }

  @media(hover: hover) {
    &:hover {
      color: $main2;

      svg {
        //stroke: $main2 !important;
        fill: $main2 !important;
      }

      .svg2 {
        stroke: $main2 !important;
      }
    }
  }
}

.headerControls__heart {
  svg {
    fill: transparent;
  }

  &.active {
    svg {
      fill: $main;
      stroke: $main !important;
    }
  }
}

.header__cartAmount {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  font-weight: 700;
  min-width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  display: block;
  color: #fff;
  background: $main;
}

.header__cartAmount {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.headerControls__link.is-active {
  .header__cartAmount {
    opacity: 1;
  }
}

@media(hover: hover) {
  .login__wrapper:hover .login__list {
    max-height: 500px;
  }
}


.headerControls__item {
  position: relative;

}

.strefa__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 0px;
  width: 520px;
}

.strefa__item {
  flex: 0 0 60%;
  padding-left: 64px;
  position: relative;
  line-height: 1.3;

  &:nth-child(2n) {
    flex: 0 0 40%;
  }

  a {
    display: block;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 32px;
  }

  span {
    white-space: nowrap;
    color: $txt-light;
    font-size: 13px;
    display: block;
  }

  strong {
    color: $txt;
    font-size: 16px;
  }
}

.hc__wrapper {
  padding: 0;
  position: absolute;

  gap: 20px;
  border-radius: 15px;
  right: 0;
  opacity: 0;
  z-index: -1000;
  top: 100%;
  height: 0;
  overflow: hidden;


  &.dropdown-active {
    z-index: 30;
    height: initial;
    opacity: 1;
    overflow: visible;
    transition: opacity .3s ease-in-out;
    padding-bottom: 20px;
    padding-top: 20px;

    .hc__list {
      display: block;
    }

    .strefa__list {
      display: flex;
    }
  }
}

.hc__list {
  position: relative;
  background: #fff;
  border: 3px solid #eff3f9;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .25);
  padding: 20px;
  border-radius: 15px;
  display: none;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #EFF3F9;
    position: absolute;
    bottom: 100%;
    right: 50px;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .header__infoTel {
    margin-left: 0;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .header__infoTel {
    span {
      width: 0;
      display: inline-block;
      position:absolute;
      z-index:-1;
      opacity: 0;
      overflow: hidden;
    }
  }
}