@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

button {
  padding: 0;
  color: #282828;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #282828;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

.hidden {
  display: none;
}

.dialog-backdrop.active {
  display: block;
}

.dialog-backdrop {
  z-index: 10000 !important;
}

.dialog__content {
  padding: 20px 50px;
}

.dialog-btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 0;
  outline: 0;
  background: 0 0;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: #212121;
  color: #fff;
  padding: 0;
  border-radius: 10px;
}
.dialog-btn-close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 2.5rem;
  position: absolute;
  top: -4px;
  left: 15px;
  transition: all 0.3s ease-out;
}

.default_dialog {
  color: #000;
  padding-bottom: 20px;
  border-radius: 10px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  max-width: 840px;
  margin-top: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.22);
  position: absolute;
  top: 50%;
  left: 50vw;
  /* move to the middle of the screen (assumes relative parent is the body/viewport) */
  transform: translate(-50%, -50%);
}
.default_dialog .dialog_content {
  padding: 15px 20px;
  max-height: 88vh;
  overflow: auto;
}
.default_dialog .dialog_content h3 {
  font-size: 18px;
}
@media (max-height: 700px), (max-width: 700px) {
  .default_dialog {
    font-size: 12px;
  }
  .default_dialog .form-checkbox label {
    font-size: 12px;
  }
  .default_dialog .m-b-20 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 640px) {
  .default_dialog .modal__container {
    padding: 30px 45px 20px 45px;
  }
  .default_dialog .modal__container h3 {
    font-size: 20px;
  }
  .default_dialog .btn--close:not(.btn) {
    position: absolute;
    top: 30px;
    right: 40px;
  }
}
@media (min-width: 768px) {
  .default_dialog h2,
.default_dialog h4 {
    font-size: 20px;
  }
  .default_dialog .btn--close:not(.btn) {
    top: 20px;
    right: 15px;
  }
  .default_dialog h2,
.default_dialog h4 {
    font-weight: 700;
    margin-bottom: 20px;
  }
  .default_dialog h2 small,
.default_dialog h4 small {
    display: block;
    margin-top: 15px;
    font-weight: 400;
  }
}
@media (max-width: 576px) {
  .default_dialog h2,
.default_dialog h4 {
    font-size: 18px;
  }
}
.default_dialog .owl-carousel__btn {
  opacity: 1;
}

/* native <dialog> element uses the ::backdrop pseudo-element */
/* dialog::backdrop, */
.dialog-backdrop {
  display: none;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
}

.dialog-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.dialog-backdrop.active {
  display: block;
}

.no-scroll {
  overflow-y: auto !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

.container {
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1570px;
}
@media (max-width: 576px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1400px) {
  .container--lg {
    padding-left: 180px;
    padding-right: 180px;
  }

  .container--lg--left {
    padding-left: 180px;
  }
}
.container--md {
  max-width: 700px;
}

.container--500, .container--xs {
  max-width: 490px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

[class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  [class*=col-] {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
}
.row--16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .row--60 {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
  .row--60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    margin-bottom: 60px;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 768px) {
  .col-md-3 {
    width: 35%;
    flex: 0 0 33.33%;
  }

  .col-md-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-50,
.col-md-6 {
    width: 50%;
    flex: 0 0 50%;
  }

  .col-md-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-md-9 {
    width: 75%;
    flex: 0 0 75%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-lg-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-8 {
    max-width: 66.6%;
    flex: 0 0 66.6%;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-20 {
    width: 20%;
    flex: 0 0 20%;
  }
}
.section {
  padding-top: 70px;
  padding-bottom: 70px;
}
@media (max-width: 576px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section--40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ml-auto {
  margin-left: auto !important;
}

.p-20 {
  padding: 20px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
@media (max-width: 768px) {
  .mb-100 {
    margin-bottom: 60px !important;
  }
}

/* lexend-deca-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-500.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-condensed-v27-latin_latin-ext-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-condensed-v27-latin_latin-ext-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-800 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/roboto-condensed-v27-latin_latin-ext-800.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  html {
    font-size: 12px;
  }
}

body {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  color: #282828;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: "Lexend Deca", sans-serif;
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip span {
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  z-index: -1000;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 5px;
  font-size: 0.75rem;
  color: #282828;
  width: 0;
  height: 0;
  overflow: hidden;
  text-align: center;
  line-height: 1.2;
}
.tooltip:hover span {
  opacity: 1;
  z-index: 1;
  height: auto;
  width: auto;
}

.tooltip--i {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-image: url("../img/ico-calendar.svg");
  background-position: left center;
  background-repeat: no-repeat;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.lh--2 {
  line-height: 2 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 600 !important;
}

.font--9 {
  font-size: 9px !important;
}

.font--10 {
  font-size: 0.625rem !important;
}

.font--12 {
  font-size: 0.75rem !important;
}

.font--14 {
  font-size: 0.875rem !important;
}

.font--18 {
  font-size: 1.125rem !important;
}

.font--20 {
  font-size: 1.3125rem !important;
}

.main {
  color: #3171DE !important;
}

.grey {
  color: #535353 !important;
}

.grey--light {
  color: #707070 !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #282828 !important;
}

.main2 {
  color: #6BE6E1 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: right !important;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #6BE6E1;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: #3171DE;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #6BE6E1;
  }
}
a:focus-visible {
  outline-offset: 2px;
}

h1,
h2,
h3 {
  letter-spacing: initial;
  line-height: 1.3;
  font-weight: 500;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 48px;
  margin-bottom: 40px;
}

h2 {
  font-size: 48px;
  margin-bottom: 40px;
}

h3 {
  font-size: 34px;
  margin-bottom: 25px;
}

.title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  color: #282828;
}

.title2 {
  font-size: 38px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 35px;
  color: #282828;
}

.title3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  color: #282828;
}

.description {
  text-align: center;
  font-size: 1.3125rem;
  margin-bottom: 50px;
}

h4,
h5,
h6 {
  line-height: 1.3;
  font-size: 1.3125rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.list--own ul {
  padding-left: 20px;
  list-style: none;
}
.list--own ul li {
  position: relative;
  margin-bottom: 5px;
}
.list--own ul li:before {
  content: ">";
  position: absolute;
  top: 0px;
  left: -20px;
  font-weight: 700;
}
.list--own ul li ul {
  padding-left: 15px;
}
.list--own ul li li:before {
  top: -8px;
  left: -13px;
  font-size: 20px;
  content: ".";
  font-weight: 700;
}

@media (max-width: 1620px) {
  h1, h2 {
    font-size: 42px;
  }

  .title2 {
    font-size: 34px;
  }

  h3, .title {
    font-size: 30px;
  }

  .title3 {
    font-size: 24px;
  }
}
@media (max-width: 992px) {
  h1, h2 {
    font-size: 36px;
  }

  .title2 {
    font-size: 30px;
  }

  h3, .title {
    font-size: 26px;
  }

  .title3 {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  h1, h2 {
    font-size: 32px;
  }

  .title2 {
    font-size: 26px;
  }

  h3, .title {
    font-size: 22px;
  }

  .title3 {
    font-size: 20px;
  }
}
ul,
ol {
  list-style: none;
}

.page__txt {
  line-height: 1.6;
  color: #535353;
  font-size: 18px;
}
.page__txt img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  display: inline-block;
  height: auto;
}
.page__txt ul,
.page__txt ol {
  padding-left: 30px;
}
.page__txt ul li:before {
  content: "•";
  color: #3171DE;
  padding-right: 8px;
}
.page__txt ol {
  list-style: decimal;
}
.page__txt ol li::marker {
  color: #3171DE;
  font-weight: 700;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
.page__txt h2:not(:first-child) {
  margin-top: 35px;
}
.page__txt h3:not(:first-child) {
  margin-top: 25px;
}
@media (max-width: 576px) {
  .page__txt {
    font-size: 16px;
  }
}

input:not([type=date], [type=number], [type=radio], [type=checkbox]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: "Lexend Deca", sans-serif;
}

button {
  color: #282828;
}

input,
textarea,
select {
  border: 1px solid #E8E9E5;
  line-height: 1.6;
  padding: 10px 15px;
  color: #000;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1rem;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  font-family: "Lexend Deca", sans-serif;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  font-family: "Lexend Deca", sans-serif;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: "Lexend Deca", sans-serif;
}

::-moz-placeholder {
  color: #6C6C6C;
  font-weight: 400;
  font-style: inherit;
  font-family: "Lexend Deca", sans-serif;
}

:-ms-input-placeholder {
  color: #6C6C6C;
  font-weight: 400;
  font-style: inherit;
  font-family: "Lexend Deca", sans-serif;
}

::placeholder {
  color: #6C6C6C;
  font-weight: 400;
  font-style: inherit;
  font-family: "Lexend Deca", sans-serif;
}

.disabled {
  color: #6C6C6C;
}

hr {
  border: none;
  border-top: 1px solid #DDDDDD;
  margin-top: 40px;
  margin-bottom: 40px;
}

table {
  max-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;
}
table * {
  line-height: 1.4;
}
table th {
  background: transparent;
  font-weight: 700;
  border-bottom: 1px solid #DDDDDD;
  padding: 10px 10px;
  vertical-align: middle;
}
table td {
  border-bottom: 1px solid #DDDDDD;
  padding: 10px 10px;
  vertical-align: middle;
}
@media (min-width: 1400px) {
  table {
    font-size: 1rem;
  }
}
table thead {
  position: relative;
}
table thead th {
  border: none;
  padding: 12px 10px 12px 10px;
  border-top: 2px solid #6BE6E1;
  border-bottom: 2px solid #6BE6E1;
}
table .align-right {
  text-align: right;
}
table .align-left {
  text-align: left;
}
table .align-center {
  text-align: center;
}
table tbody tr:first-child th,
table tbody tr:first-child td {
  border-top-width: 5px;
}

.table__wrapper {
  position: relative;
}
.table__wrapper table {
  width: 100%;
  position: relative;
}
.table__wrapper table a {
  display: inline-block;
  padding: 1px 5px;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 800px;
    width: initial;
  }
  .table__wrapper table th,
.table__wrapper table td {
    padding: 8px 5px;
  }
  .table__wrapper.table__wrapper--big table {
    min-width: 1300px;
    width: initial;
  }
}

.btn--table {
  background: #3171DE;
  color: #fff;
  padding: 5px 10px !important;
  margin-top: 7px;
  text-decoration: none !important;
}
@media (hover: hover) {
  .btn--table:hover {
    background: #6BE6E1;
    color: #fff;
  }
}

.listBlue__wrapper {
  list-style: none !important;
  padding-left: 0 !important;
  color: #535353;
}
.listBlue__wrapper li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 20px;
}
.listBlue__wrapper li:before {
  content: "";
  width: 7px;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  background: #3171DE;
  top: 0;
  left: 0;
}

.listCheck__wrapper {
  list-style: none !important;
  padding-left: 0 !important;
  color: #535353;
}
.listCheck__wrapper li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
}
.listCheck__wrapper li:before {
  content: "";
  width: 20px;
  height: 24px;
  display: inline-block;
  background-image: url("../img/blue-check.svg");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.img__wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__wrapper img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.of-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.bg--main2 {
  background: #6BE6E1 !important;
}

.bg--main {
  background: #3171DE !important;
}

.bg--grey {
  background: #eee !important;
}

.bg--light {
  background: #EFF1F7 !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -100;
  opacity: 0;
}

.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: #282828 !important;
  display: block;
}

.alert-success {
  background-color: #ebf3e8 !important;
  color: #007A3B !important;
}

.alert-info {
  background-color: #eaf6fb !important;
}

.alert-warning {
  background-color: #f8f8cb !important;
}

.alert-danger {
  background-color: #faeded !important;
  color: #ca0038 !important;
}

.alert-grey {
  background-color: #eee !important;
}

.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

fieldset {
  border: none;
  padding: 0;
}

.form-h-info {
  margin-top: 5px;
  font-size: 0.625rem;
  max-height: 0;
  overflow: hidden;
  color: #707070;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;
}
.form__select label {
  color: #6C6C6C;
  font-size: 0.8125rem;
  margin-bottom: 6px;
  font-weight: 500;
  display: block;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  padding: 8px 80px 8px 8px;
  border: 1px solid #E8E9E5;
  display: block;
  background: transparent;
  width: 100%;
  padding-right: 40px;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus, .form__select select:focus-visible {
  outline: 2px solid #3B8245;
  border-color: #3B8245;
  box-shadow: none;
  outline-offset: 0;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 1rem;
  color: #000;
}
.form__select:after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 18px;
  z-index: -1;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}

.form__checkbox {
  margin-bottom: 15px;
}
.form__checkbox input {
  position: absolute;
  left: -5000px;
  opacity: 0;
}
.form__checkbox a {
  text-decoration: underline;
  color: #282828;
}
@media (hover: hover) {
  .form__checkbox a:hover {
    color: #3171DE;
  }
}
.form__checkbox label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  color: #282828;
  font-size: 0.875rem;
  line-height: 1.4;
  cursor: pointer;
  display: inline-block;
}
.form__checkbox label:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  border: 1px solid #E8E9E5;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
}
.form__checkbox input:checked ~ label:before {
  background-color: #3171DE;
}
.form__checkbox input:checked ~ label:after {
  width: 10px;
  height: 6px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #00af9a;
  outline-offset: 2px;
}
.form__checkbox input[type=radio] ~ label:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background: #3171DE;
  top: 4px;
  left: 5px;
}
.form__checkbox input[type=radio]:checked ~ label:before {
  background: #fff;
}
.form__checkbox.is--invalid label:before {
  border-color: #E4513D;
}

.form__checkbox--small label {
  font-size: 0.625rem;
}

.form__input {
  margin-bottom: 15px;
  position: relative;
}
.form__input input {
  border: 1px solid #DDDDDD;
  width: 100%;
  display: block;
  color: #000;
  line-height: 1.3;
}
.form__input label {
  font-size: 0.8125rem;
  margin-bottom: 3px;
  font-weight: 400;
  color: #6C6C6C;
  display: block;
  margin-left: 20px;
}
.form__input input,
.form__input textarea {
  border: 1px solid #E8E9E5;
  width: 100%;
  display: block;
  color: #000;
  font-size: 16px;
  padding: 15px 35px 12px 20px;
  border-radius: 25px;
}
.form__input input:focus, .form__input input:focus-visible,
.form__input textarea:focus,
.form__input textarea:focus-visible {
  outline: 2px solid #000;
  border-color: #000 !important;
  box-shadow: none;
  outline-offset: 0;
}
.form__input.is--invalid input,
.form__input.is--invalid textarea {
  border-color: #E4513D !important;
}
.form__input.is--invalid input:focus, .form__input.is--invalid input:focus-visible,
.form__input.is--invalid textarea:focus,
.form__input.is--invalid textarea:focus-visible {
  outline: 2px solid #E4513D;
  border-color: #E4513D;
  box-shadow: none;
}
.form__input.is--invalid:after {
  content: "+";
  transform: rotate(45deg);
  color: #E4513D;
  position: absolute;
  background: transparent;
  top: 32px;
  right: 10px;
  font-size: 1.3125rem;
}
.form__input.is--valid input,
.form__input.is--valid textarea {
  border-color: #3B8245 !important;
}
.form__input.is--valid input:focus, .form__input.is--valid input:focus-visible,
.form__input.is--valid textarea:focus,
.form__input.is--valid textarea:focus-visible {
  outline-color: #3B8245;
}
.form__input.is--valid:after {
  content: "";
  transform: rotate(-45deg);
  border-left: 2px solid #3B8245;
  border-bottom: 2px solid #3B8245;
  position: absolute;
  top: 42px;
  right: 15px;
  display: block;
  width: 12px;
  height: 8px;
}

.form__input--bold label {
  font-size: 1.3125rem;
  color: #282828;
  font-weight: 700;
  margin-bottom: 10px;
}
.form__input--bold.is--valid:after {
  top: 52px;
}
.form__input--bold.is--invalid:after {
  top: 46px;
}

.form__input--error {
  color: #E4513D;
  font-size: 0.625rem;
  display: block;
  margin-top: 5px;
}

.form__checkbox .form__input--error {
  margin-bottom: 5px;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.form-s fieldset {
  margin-bottom: 30px;
}
.form-s fieldset {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.form-s .form__input--25 {
  max-width: calc(50% - 15px);
}
.form-s .form__input,
.form-s .form__select {
  width: 100%;
}
@media (min-width: 768px) {
  .form-s .form__input,
.form-s .form__select {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .form-s .form__input,
.form-s .form__select,
.form-s .form__checkbox--50 {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
  .form-s .form__input--25 {
    max-width: calc(25% - 15px);
  }
}

.cart__customer {
  display: flex;
  margin-bottom: 30px;
  margin-top: 40px;
  align-items: center;
  gap: 15px;
  font-size: 0.875rem;
}
.cart__customer .form__checkbox {
  margin: 0;
}
.cart__customer .form__checkbox label {
  font-size: 1rem;
  font-weight: 700;
  border: none;
  color: #282828;
  min-height: auto;
}
.cart__customer .form__checkbox label:before {
  top: 50% !important;
  transform: translateY(-50%);
}
.cart__customer .form__checkbox label:after {
  top: 50% !important;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .cart__customer {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.button__password {
  position: absolute;
  right: 2px;
  top: 26px;
  width: 40px;
  height: 38px;
  background-image: url("../img/eye.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  z-index: 20;
  background-color: transparent;
}
.button__password:after {
  content: "";
  display: block;
  height: 1px;
  background: #ddd;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 20px;
  top: 18px;
  left: 10px;
}
.button__password.show:after {
  top: 10px;
  left: 10px;
  width: 0px;
}

.form__password.form__input:after {
  content: none !important;
  opacity: 0;
}

.btn-gnerate {
  background: #fff;
  font-size: 0.875rem;
  color: #282828;
  padding: 10px 15px;
  white-space: nowrap;
  font-weight: 700;
  border: 1px solid #282828 !important;
}
@media (hover: hover) {
  .btn-gnerate:hover {
    background-color: #EFF1F7;
  }
}

.generate__score {
  transition: all 0.3s ease-out;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  line-height: 1.2;
  gap: 10px;
}
@media (min-width: 1200px) {
  .generate__score {
    gap: 20px;
  }
}
@media (max-width: 410px) {
  .generate__score {
    font-size: 0.75rem;
  }
}
.generate__score .generate__password {
  display: block;
}
.generate__score.open {
  max-height: 100px;
}

#input-accept {
  border: none;
  background: none;
  color: #3171DE;
  padding: 10px 0;
  font-size: 0.875rem;
  font-weight: 700;
}

.generate__wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  color: #282828;
}
@media (min-width: 992px) {
  .generate__wrapper {
    margin-top: 25px;
  }
}
@media (min-width: 992px) {
  .generate__wrapper {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    align-items: flex-start;
  }
}

@media (max-width: 390px) {
  .generate__wrapper {
    flex-direction: column;
  }

  .generate__score .generate__password {
    display: inline-block;
    margin-left: 10px;
  }
}
.go-top {
  position: absolute;
  top: -40px;
  right: 0;
  display: inline-block;
  background-image: url("../img/ico_top.svg");
  background-repeat: no-repeat;
  background-position: center top;
  border: none;
  background-color: transparent;
  padding-top: 75px;
  font-weight: 700;
}
.go-top span {
  font-size: 0.75rem;
  color: #AAAAAA;
  text-transform: uppercase;
  transform: rotate(-90deg);
  display: block;
  padding-right: 65px;
}

.footer {
  position: relative;
  background: #eee;
  z-index: 1;
  line-height: 1.8;
}

.footer__center {
  padding-top: 60px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footer__col h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.footer__list {
  list-style: none;
  padding-left: 0;
}
.footer__list a {
  color: #282828;
  font-size: 14px;
  display: inline-block;
  padding: 4px 0;
}
@media (hover: hover) {
  .footer__list a:hover {
    color: #3171DE;
  }
}

.footer__social {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.footer__social a {
  min-width: 48px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__social a svg {
  fill: #282828;
  transform-origin: center;
  transform: scale(2);
}

.footer__contact {
  font-size: 14px;
}
.footer__contact a {
  font-weight: 700;
}
.footer__contact span:after {
  content: "";
  width: 102px;
  height: 1px;
  background: rgba(230, 226, 219, 0.2);
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  position: relative;
  bottom: 0px;
}
.footer__contact p {
  margin-bottom: 5px;
}
.footer__contact p:nth-child(2) span:after {
  width: 70px;
}

.footer__bottom {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 2px solid #ECECEC;
  position: relative;
  z-index: 10;
  font-size: 11px;
  color: #707070;
}
.footer__bottom .container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__bottom img {
  opacity: 0.5;
}

.footer__brand {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  gap: 5px;
}
.footer__brand a {
  position: relative;
  top: -2px;
}

@media (max-width: 576px) {
  .footer__col h3 {
    margin-bottom: 15px;
  }

  .footer .footer__col {
    margin-bottom: 50px;
  }

  .footer__brand img {
    height: 8px;
    width: auto;
  }

  .footer__bottom {
    opacity: 0.7;
  }
}
@-webkit-keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
.menu-is-fixed {
  padding-top: 130px;
}

.subpage .header {
  border-bottom: 1px solid #EFF1F7;
}

.header {
  background: rgba(255, 255, 255, 0.7);
  position: relative;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
}
.header.fixed-header-scroll {
  position: fixed;
  top: 0;
  -webkit-animation-name: fixedMenuDown;
  animation-name: fixedMenuDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
}
.header.fixed-header-scroll .header__top {
  display: none;
}
.header.fixed-header-scroll .header__bottom {
  height: 50px;
}
.header.fixed-header-scroll .btn--bell {
  min-height: 40px;
}
.header.fixed-header-scroll .header__logo {
  padding-right: 30px;
}

.header__topBg {
  background: #F3F3F3;
}

.header__infoTel {
  margin-left: auto;
  margin-right: auto;
}
.header__infoTel span {
  color: #707070;
  font-size: 13px;
  margin-right: 10px;
}

.header__top {
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 45px;
  padding-left: 300px;
}

.header__logo {
  max-width: 210px;
  flex: 0 0 210px;
}
.header__logo img {
  width: 100%;
}

.nav-toggle {
  width: 50px;
  padding: 0;
  padding-left: 50px;
  height: 50px;
  background: none;
  border: none;
  display: none;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-size: 1.3125rem;
  right: 50px;
  line-height: 50px;
  bottom: 8px;
}
.nav-toggle strong {
  margin-left: -20px;
}
.nav-toggle span {
  width: 26px;
  height: 2px;
  background: #282828;
  display: block;
  position: absolute;
  left: 12px;
  top: 15px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}
.nav-toggle span:nth-child(2) {
  top: 25px;
}
.nav-toggle span:nth-child(3) {
  top: 35px;
}
.nav-toggle.open span:first-child {
  transform: rotate(45deg);
  top: 24px;
}
.nav-toggle.open span:nth-child(2) {
  left: 50px;
  opacity: 0;
}
.nav-toggle.open span:nth-child(3) {
  top: 24px;
  transform: rotate(-45deg);
}

.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  position: relative;
  z-index: 10;
  width: 100%;
}

.header__social {
  display: flex;
  align-items: center;
  margin-top: -5px;
}
.header__social a {
  width: 48px;
  height: 48px;
  display: inline-block;
  fill: #282828;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.link--social {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-color: trnasparent;
}

.link--fb {
  background-image: url("../img/fb.svg");
}

.link--insta {
  background-image: url("../img/insta.svg");
}

header > ul > li {
  position: relative;
}

.nav__wrapper {
  display: flex;
  align-items: center;
  gap: 0px;
  width: 100%;
  justify-content: center;
}

.nav a {
  color: #282828;
  font-size: 16px;
  font-weight: 700;
  padding: 17px 12px;
  display: inline-block;
  transition-property: color;
  font-weight: 700;
  white-space: nowrap;
}
@media (hover: hover) {
  .nav a:hover {
    color: #3171DE;
  }
}

.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

.menu-open .menu-right__wrapper {
  z-index: 0;
}

.menu__item--has-children {
  position: relative;
}

@media (min-width: 1200px) {
  .nav a.active {
    color: #3171DE;
  }

  .menu__item--has-children {
    position: relative;
  }
  .menu__item--has-children > a {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .menu__item--has-children .nav__level-2 {
    padding: 15px 20px 0 20px;
    position: absolute;
    width: 180px;
    gap: 20px;
    border-radius: 15px;
    left: 0px;
    opacity: 0;
    z-index: -1000;
    top: 100%;
    height: 0;
    overflow: hidden;
    background: #fff;
    border: 3px solid #EFF3F9;
    box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.25);
  }
  .menu__item--has-children .nav__level-2 ul:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #EFF3F9;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .menu__item--has-children .nav__level-2 li {
    border-top: none;
  }
  .menu__item--has-children .nav__level-2 li a {
    font-size: 1rem;
    color: #282828;
    display: block;
    padding: 3px 0px 3px 0px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    font-weight: 700;
    white-space: initial;
  }
  .menu__item--has-children .nav__level-2 a:hover {
    color: #3171DE;
  }
  .menu__item--has-children .nav__level-2 a:focus-visible {
    outline-color: #6BE6E1;
    outline-offset: -2px;
  }
  .menu__item--has-children.open .nav__level-2 {
    z-index: 30;
    height: initial;
    opacity: 1;
    overflow: visible;
    transition: opacity 0.3s ease-in-out;
    padding-bottom: 10px;
    padding-top: 15px;
  }

  .menu__item--has-children:hover > a {
    color: #3171DE;
  }
  .menu__item--has-children:hover .nav__level-2 {
    z-index: 30;
    height: initial;
    opacity: 1;
    overflow: visible;
    transition: opacity 0.3s ease-in-out;
    padding-bottom: 10px;
    padding-top: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1360px) {
  .nav a {
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (max-width: 1200px) {
  .menu__item--has-children {
    padding-right: 50px;
  }
  .menu__item--has-children:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-left: 2px solid #282828;
    border-bottom: 2px solid #282828;
    position: absolute;
    right: 10px;
    top: 18px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }
  .menu__item--has-children.m-open:after {
    top: 22px;
    transform: rotate(135deg);
  }

  .header.fixed-header-scroll .header__top {
    align-items: center;
    height: 50px;
  }
  .header.fixed-header-scroll .headerForm input {
    background: #fff;
    border: 1px solid #AAAAAA;
  }
  .header.fixed-header-scroll .nav-toggle {
    top: 0px;
  }
  .header.fixed-header-scroll .nav__level-2 {
    top: 50px;
    height: calc(100vh - 50px);
  }
  .header.fixed-header-scroll .activeLi > a {
    top: 50px;
  }

  .header__social {
    margin-right: 10px;
  }

  .header {
    transition: background 0s ease-out 0.3s;
  }

  .header__top {
    padding-left: 60px;
    position: relative;
    align-items: center;
    gap: 20px;
  }

  .headerForm {
    padding-right: 20px;
  }

  .header__right {
    gap: 0;
  }

  .header__bottom {
    position: static;
    flex: 0 0 0;
    height: 65px;
    padding-right: 60px;
  }

  .btn__login {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    padding: 0;
    text-indent: -1000px;
    overflow: hidden;
    height: 48px;
    background: transparent url("../img/user.svg") center no-repeat;
    background-size: 15px;
  }

  .menu__item--has-children {
    position: relative;
  }
  .menu__item--has-children > a {
    width: 100% !important;
    position: relative;
    z-index: 1;
  }
  .menu__item--has-children:after {
    width: 12px;
    height: 12px;
    top: 18px;
    transform: rotate(-135deg);
    z-index: 0;
  }

  .nav__level-1 > li:first-child {
    margin-top: 15px;
  }
  .nav__level-1 > li:last-child {
    margin-bottom: 15px;
  }
  .nav__level-1 > li > a {
    padding: 12px 0;
    font-size: 19px;
  }

  .headerForm {
    width: 100%;
  }

  .nav .nav__wrapper {
    position: absolute;
    top: 100%;
    display: block;
    background: #eee;
    width: 100%;
    left: -100%;
    padding: 0 20px 80px 20px;
    height: calc(100vh - 80px);
    margin-top: 0;
    transition: all 0.3s ease-out;
  }
  .nav .nav__wrapper.open {
    left: 0;
    box-shadow: -2px 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .nav .nav__wrapper li {
    width: 100%;
  }
  .nav .nav__wrapper > li:last-child {
    margin-bottom: 25px;
  }

  .nav__level-2 {
    display: none;
  }
  .nav__level-2 ul {
    padding-left: 20px;
  }
  .nav__level-2 a {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 8px 0;
  }
  .nav {
    width: 100%;
  }

  .nav-toggle {
    display: block;
    position: absolute;
  }

  .nav__wrapper.nav__level-1 ul.open {
    max-height: 600px;
    overflow: auto;
  }

  .nav {
    margin-left: auto;
    flex: 0 0 0px;
    max-width: 0px;
  }

  .header.fixed-header-scroll .header__bottom {
    margin-left: auto;
    justify-content: flex-end;
  }
  .header.fixed-header-scroll .header__left {
    padding-top: 0;
    padding-bottom: 0;
  }

  .header__social {
    display: none;
  }

  .nav-toggle {
    right: 20px;
  }

  .header .btn--bell {
    min-height: 40px;
    padding: 5px 15px 5px 35px;
    background-position: left 13px center;
  }
  .header .btn--bell span {
    display: none;
  }
}
@media (max-width: 576px) {
  .header__top {
    padding-left: 20px;
    gap: 10px;
  }

  .header__logo {
    max-width: 120px;
    flex: 0 0 120px;
  }

  .headerControls {
    gap: 10px !important;
  }

  .header.fixed-header-scroll .header__logo {
    padding-right: 0px;
  }
}
.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

@media (max-width: 440px) {
  .header .headerControls__link, .header .link--tel {
    font-size: 13px;
  }
}
@media (max-width: 390px) {
  .header .headerControls__link svg, .header .link--tel svg {
    display: none;
  }
}
.headerControls {
  display: flex;
  gap: 50px;
  align-items: center;
  list-style: none;
  padding: 0;
}

.headerControls__link {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  position: relative;
  border: none;
  font-size: 16px;
  color: #3171DE;
  line-height: 1.5;
}
.headerControls__link svg {
  transition: all 0.3s ease-in-out;
  fill: #3171DE;
}
.headerControls__link .svg2 {
  stroke: #3171DE !important;
}
@media (hover: hover) {
  .headerControls__link:hover {
    color: #6BE6E1;
  }
  .headerControls__link:hover svg {
    fill: #6BE6E1 !important;
  }
  .headerControls__link:hover .svg2 {
    stroke: #6BE6E1 !important;
  }
}

.headerControls__heart svg {
  fill: transparent;
}
.headerControls__heart.active svg {
  fill: #3171DE;
  stroke: #3171DE !important;
}

.header__cartAmount {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  font-weight: 700;
  min-width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  border-radius: 8px;
  padding-left: 3px;
  padding-right: 3px;
  display: block;
  color: #fff;
  background: #3171DE;
}

.header__cartAmount {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.headerControls__link.is-active .header__cartAmount {
  opacity: 1;
}

@media (hover: hover) {
  .login__wrapper:hover .login__list {
    max-height: 500px;
  }
}
.headerControls__item {
  position: relative;
}

.strefa__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 0px;
  width: 520px;
}

.strefa__item {
  flex: 0 0 60%;
  padding-left: 64px;
  position: relative;
  line-height: 1.3;
}
.strefa__item:nth-child(2n) {
  flex: 0 0 40%;
}
.strefa__item a {
  display: block;
}
.strefa__item img {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 32px;
}
.strefa__item span {
  white-space: nowrap;
  color: #707070;
  font-size: 13px;
  display: block;
}
.strefa__item strong {
  color: #282828;
  font-size: 16px;
}

.hc__wrapper {
  padding: 0;
  position: absolute;
  gap: 20px;
  border-radius: 15px;
  right: 0;
  opacity: 0;
  z-index: -1000;
  top: 100%;
  height: 0;
  overflow: hidden;
}
.hc__wrapper.dropdown-active {
  z-index: 30;
  height: initial;
  opacity: 1;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  padding-bottom: 20px;
  padding-top: 20px;
}
.hc__wrapper.dropdown-active .hc__list {
  display: block;
}
.hc__wrapper.dropdown-active .strefa__list {
  display: flex;
}

.hc__list {
  position: relative;
  background: #fff;
  border: 3px solid #eff3f9;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 15px;
  display: none;
}
.hc__list:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #EFF3F9;
  position: absolute;
  bottom: 100%;
  right: 50px;
}

@media (max-width: 1200px) {
  .header__infoTel {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .header__infoTel span {
    width: 0;
    display: inline-block;
    position: absolute;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
  }
}
.breadcrumbs__list {
  display: flex;
  padding: 15px 0;
  flex-wrap: wrap;
}
.breadcrumbs__list.breadcrumbs--border {
  border-bottom: 1px solid #DDDDDD;
}
.breadcrumbs__list span,
.breadcrumbs__list a {
  font-size: 14px;
  color: #282828;
  display: inline-block;
  padding: 7px 0;
  line-height: 1;
  font-weight: 300;
}
.breadcrumbs__list span:before,
.breadcrumbs__list a:before {
  margin-right: 10px;
  color: #282828;
  content: "/";
}
.breadcrumbs__list li:first-child a:before {
  content: none;
}
.breadcrumbs__list a {
  margin-right: 10px;
}
.breadcrumbs__list a:focus, .breadcrumbs__list a:hover {
  outline: none;
  color: #6BE6E1;
}
@media (max-width: 576px) {
  .breadcrumbs__list span,
.breadcrumbs__list a {
    font-size: 12px;
  }
}

.pageHero__wrapper {
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 40px;
}
.pageHero__wrapper h1, .pageHero__wrapper h2 {
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .pageHero__wrapper {
    margin-bottom: 30px;
  }
}

.accordion__trigger {
  padding: 20px 40px 20px 0;
  font-weight: 700;
  border: none;
  background: transparent;
  font-size: 18px;
  width: 100%;
  text-align: left;
  color: #000;
  position: relative;
}
.accordion__trigger:after {
  content: "";
  position: absolute;
  top: 22px;
  right: 10px;
  display: block;
  width: 12px;
  height: 12px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  transition: all 0.3s ease-out;
  transform-origin: center;
}
.accordion__trigger.active:after, .accordion__trigger[aria-expanded=true]:after {
  top: 26px;
  transform: rotate(135deg);
}

.accordion__content {
  padding: 0;
  background: transparent;
  font-size: 18px;
  width: 100%;
  display: none;
}
.accordion__content p,
.accordion__content ol,
.accordion__content ul {
  margin-bottom: 15px;
}

.accordion__panel {
  padding: 0 0 10px 0;
}

.accordion {
  border-bottom: 1px solid #dedede;
}

.tabs__navlist {
  display: inline-flex;
  gap: 10px 25px;
  flex-wrap: wrap;
  position: relative;
  max-width: 100%;
  margin-bottom: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.tabs__nav--btn {
  padding: 9px 20px;
  border: 1px solid #B5B5B5;
  background: transparent;
  display: inline-block;
  border-radius: 25px;
  background-position: left 18px center;
  background-repeat: no-repeat;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  color: #282828;
}
@media (hover: hover) {
  .tabs__nav--btn:hover {
    color: #3171DE;
  }
}
.tabs__nav--btn:focus-visible {
  border-color: #3171DE;
  color: #3171DE;
}
.tabs__nav--btn[aria-selected=true], .tabs__nav--btn.active {
  border-color: #3171DE;
  color: #fff;
  background-color: #3171DE;
  padding-left: 44px;
  background-image: url("../img/check-white.svg");
}

@media (max-width: 1620px) {
  .tabs__navlist {
    gap: 8px;
  }

  .tabs__nav--btn {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .tabs__nav--btn {
    padding: 8px 15px;
  }
}
@media (max-width: 576px) {
  .tabs__nav--btn {
    font-size: 14px;
  }

  .tabs__nav--btn[aria-selected=true] {
    background-position: left 14px center;
    padding-left: 34px !important;
  }
}
.tabs__panel {
  display: none;
}
.tabs__panel.is-current {
  display: block;
}
@media (min-width: 768px) {
  .tabs__panel {
    margin-bottom: 30px;
  }
}

.tabProduct__container {
  position: relative;
}
.tabProduct__container .swiper-slide {
  height: initial;
}

.history--back {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 0px;
}
.history--back:before {
  content: "" !important;
  width: 50px;
  height: 50px;
  background: url("../img/swiper-arrow.svg") center no-repeat;
  background-size: 16px;
  display: block;
}

.link--underline {
  text-decoration: underline;
  color: #282828;
}
@media (hover: hover) {
  .link--underline:hover {
    color: #6BE6E1;
  }
}

.link--black {
  color: #282828 !important;
}
@media (hover: hover) {
  .link--black:hover {
    color: #3171DE !important;
  }
}

.link {
  background-size: 16px;
  color: #282828;
  display: block;
  padding: 3px 0 3px 25px;
  font-size: 18px;
}
@media (hover: hover) {
  .link:hover {
    color: #3171DE;
  }
}

.link--tel {
  padding-left: 22px;
  font-weight: 600;
  background: url("../img/ico-phone.svg") center left no-repeat;
  background-size: 16px;
}

.link--tel2 {
  background: url("../img/ico-phone2.svg") center left no-repeat;
}

.link--mail {
  background: url("../img/ico-mail.svg") center left no-repeat;
}

.link--underline-main {
  text-decoration: underline;
  color: #3171DE;
}
@media (hover: hover) {
  .link--underline-main:hover {
    color: #6BE6E1;
  }
}

.btn {
  border: none;
  background-color: #3171DE;
  color: #fff !important;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-out;
  border: 1px solid transparent;
  line-height: 1.2;
  padding: 5px 30px;
  vertical-align: middle;
  text-align: center;
  border-radius: 25px;
}
@media (hover: hover) {
  .btn:hover {
    background-color: #6BE6E1;
    color: #fff !important;
  }
}
@media (max-width: 768px) {
  .btn {
    padding: 15px 30px;
    min-width: initial;
  }
}

.btn--red {
  background-color: #E4513D;
}

.btn--bell {
  padding-left: 45px;
  background-image: url("../img/ico-bell.svg");
  background-position: left 20px center;
  background-repeat: no-repeat;
  background-size: 14px;
}

.btn--arrow {
  padding-right: 70px;
  text-align: left;
}
.btn--arrow:after {
  content: url("../img/arrow-right.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  width: 18px;
  display: inline-block;
}
@media (max-width: 768px) {
  .btn--arrow {
    padding-right: 60px;
  }
  .btn--arrow:after {
    right: 25px;
  }
}

.btn--white {
  background: #fff;
  color: #3171DE !important;
}

.btn--sale {
  padding-left: 45px;
  background-image: url("../img/ico-sale.svg");
  background-position: left 10px center;
  background-repeat: no-repeat;
  background-size: 32px;
  display: inline-flex;
}
.btn--sale:first-letter {
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .btn--sale span {
    display: none;
  }
}

.btn--big {
  font-size: 20px;
  padding: 10px 50px;
}
@media (max-width: 768px) {
  .btn--big {
    font-size: 18px;
    padding: 12px 40px;
  }
}

.product__title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;
  line-height: 1.2;
  color: #282828;
}

.product__figure {
  position: relative;
  height: 0;
  padding-top: 92%;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}
.product__figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: all 0.3s ease-out;
}

.product__bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.product__discount {
  width: 50px;
  height: 50px;
  background: #943CCB;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
}

.product__discount--2 {
  width: 90px;
  height: 90px;
  font-size: 33px;
}

.product__priceOld {
  text-decoration: line-through;
  color: #282828;
  font-size: 18px;
}

.product__price {
  color: #E4513D;
  font-size: 18px;
  font-weight: 700;
}

.product__priceNew {
  color: #3171DE;
}

.product__item {
  margin-bottom: 50px;
  position: relative;
}
.product__item .product__date {
  margin-bottom: 10px;
}
.product__item .product__price {
  margin-top: auto;
}
.product__item .product__date, .product__item .product__title, .product__item .product__price {
  padding-left: 25px;
  padding-right: 20px;
}

.product__label {
  background: #6BE6E1;
  color: #3171DE;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 10px;
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;
  white-space: nowrap;
  font-family: "Roboto Condensed", sans-serif;
}
.product__label:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  left: 4px;
  top: 4px;
  border: 1px solid #6BE6E1;
}
@media (max-width: 1620px) {
  .product__label {
    padding: 7px 10px;
    top: 18px;
  }
}

.product__date {
  color: #707070;
  font-size: 14px;
}

.product__link {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding-bottom: 20px;
  height: 100%;
}
@media (hover: hover) {
  .product__link:hover .product__figure img, .product__link.active .product__figure img {
    transform: translate(-50%, -50%) scale(1.1);
  }
  .product__link:hover .product__title, .product__link.active .product__title {
    color: #3171DE;
  }
}

.blog {
  background: #eee;
}

.blog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 0;
}

.blog__item {
  flex: 0 0 100%;
}
.blog__item a {
  display: block;
  height: 100%;
}

.blog__figure {
  width: 100%;
  padding-top: 64%;
  position: relative;
  overflow: hidden;
  height: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.blog__figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-out;
}

.blog__title {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 400;
  margin-bottom: 0;
  color: #282828;
}

.blog__data {
  color: #707070;
  position: relative;
  font-size: 14px;
  display: block;
  line-height: 1;
}

.blog__txt {
  padding: 30px;
}

.blog__data--p0 {
  padding-left: 30px;
  padding-right: 0px;
}

.blog__link {
  padding-bottom: 60px;
  position: relative;
  border-radius: 15px;
  background: #fff;
}
.blog__link:after {
  content: "";
  position: absolute;
  right: 30px;
  bottom: 30px;
  transition: all 0.3s ease-out;
  width: 40px;
  height: 40px;
  background-image: url("../img/arrow-right-blue.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}
@media (hover: hover) {
  .blog__link:hover:after {
    background-color: #6BE6E1;
  }
  .blog__link:hover .blog__figure img {
    transform: translate(-50%, -50%) scale(1.1);
  }
  .blog__link:hover .blog__title {
    color: #3171DE;
  }
}

@media (min-width: 576px) {
  .blog__item {
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
}
@media (min-width: 992px) {
  .blog__item {
    max-width: calc(33.33% - 20px);
    flex: 0 0 33.33%;
  }
}
#scrollDown {
  position: absolute;
  bottom: 80px;
  right: calc(50vw + 700px);
  z-index: 20;
  color: #3171DE;
  text-transform: uppercase;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  font-size: 10px;
  font-weight: 700;
  transform: rotate(-90deg);
  background: none;
  border: none;
  transition: none;
}
#scrollDown:before {
  content: url("../img/arrow-right-blue.svg");
  transform: rotate(-180deg);
  width: 10px;
}
@media (max-width: 1620px) {
  #scrollDown {
    right: calc(50vw + 520px);
  }
}
@media (max-width: 1200px) {
  #scrollDown {
    right: calc(50vw + 400px);
  }
}
@media (max-width: 992px) {
  #scrollDown {
    right: initial;
    left: 10px;
  }
}

.video__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-top: -30px;
  margin-bottom: 100px;
}
.video__wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  width: 100%;
}

.video__img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  width: 60px;
  height: 60px;
  background-image: url("../img/play.svg");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border: none;
}

.video__title {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  z-index: 5;
  font-weight: 400;
  width: 100%;
}

@media (max-width: 768px) {
  .video__wrapper {
    margin-bottom: 50px;
  }

  .video__title {
    top: 70%;
    font-size: 16px;
  }
}
.feature__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.feature__item {
  display: flex;
  align-items: center;
  gap: 15px;
}
.feature__item strong {
  display: block;
}
.feature__item span {
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .feature__list {
    justify-content: center;
    flex-wrap: wrap;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 576px) {
  .feature__list {
    width: 100%;
    flex-wrap: nowrap;
    overflow: auto;
  }
}